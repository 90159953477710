import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
//import component pieces
import ProductContentArea from '../templates/product-content-area'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function ProductPage({ data, pageContext }) {
  const layoutPieces = data.contentfulProductSuitePageTemplate1.pageComponents

  return (
    <Layout header={data?.contentfulProductSuitePageTemplate1?.header} footer={data?.contentfulProductSuitePageTemplate1?.footer}>
      <ProductContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta name="description" content={data?.contentfulProductSuitePageTemplate1?.metaDescription} />
    <title>{data?.contentfulProductSuitePageTemplate1?.metaTitle}</title>
    <meta property="og:title" content={data?.contentfulProductSuitePageTemplate1?.ogMetaTitle ? data.contentfulProductSuitePageTemplate1.ogMetaTitle : data?.contentfulProductSuitePageTemplate1?.metaTitle} />
    <meta property="og:description" content={data?.contentfulProductSuitePageTemplate1?.ogMetaDescription ? data.contentfulProductSuitePageTemplate1.ogMetaDescription : data?.contentfulProductSuitePageTemplate1?.metaDescription} />
    <meta property="og:type" content={data?.contentfulProductSuitePageTemplate1?.ogMetaType} />
    <meta property="og:url" content={data?.contentfulProductSuitePageTemplate1?.slug ? `https://www.entrata.com${data?.contentfulProductSuitePageTemplate1?.slug}` : undefined} />
    <meta property="og:image" content={data?.contentfulProductSuitePageTemplate1?.ogMetaImage?.image ? data?.contentfulProductSuitePageTemplate1?.ogMetaImage?.image?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query ProductComponentPieces($slug: String!) {
    contentfulProductSuitePageTemplate1(slug: { eq: $slug }) {
      slug
      header
      footer
      metaDescription
      metaTitle
      ogMetaTitle
      ogMetaDescription
      ogMetaType
      ogMetaImage {
        image {
          url
        }
      }
      pageComponents {
        ... on Node {
          internal {
            type
          }
          ... on ContentfulProductBanner {
            ...ProductBanner
          }
          ... on ContentfulPortfolioCarousel {
            ...ClientFeature
          }
          ... on ContentfulDuplexFeature {
            ...DuplexFeature
          }
          ... on ContentfulAttributeListComponent {
            ...AttributeListComponent
          }
          ... on ContentfulQuoteDuplex {
            ...QuoteDuplex
          }
          ... on ContentfulNewsFeature {
            id
            backgroundColor
            mainHeadline
            allNewsLink {
              fullUrl
              text
              slug
            }
            newsCards {
              headlineText
              linkButton {
                mediaLinkCaption
                mediaLinkUrl
              }
            }
          }
          ... on ContentfulJoinTheTeamFeature {
            ...JoinTheTeamFeature
          }
          ... on ContentfulBucketComponent {
            ...BucketComponent
          }
          ... on ContentfulCenteredQuote {
            ...CenteredQuote
          }
          ... on ContentfulImageSliderComponent {
            ...ImageSliderComponent
          }
          ... on ContentfulFeatureSliderComponent {
            ...FeatureSliderComponent
          }
          ... on ContentfulProductComparison {
            ...ProductComparison
          }
          ... on ContentfulVideoFeature {
            ...VideoFocus
          }
        }
      }
    }
  }
`
